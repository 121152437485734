// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_bIY7tv3cV4lr5mo14E", // Keyword Image Modifier 
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/5kA4h96Roc3I1oseVt", // Keyword Image Modifier

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Keyword Image Modifier AI 🎨",
    HOME_PAGE_TITLE: "Home - Keyword Image Modifier AI 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Keyword Image Modifier AI 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Keyword Image Modifier AI 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to modify images using keywords and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "kw_img_modifier_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/kw-img-modifier-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;